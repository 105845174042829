import React from 'react';
import Layout from '../../components/layout';
import TitleHeader from '../../components/TitleHeader';
import BlogPost from '../../components/BlogPost';
// import Lady from '../../images/fbpost.png';
import Odbudowa from '../../images/blog/odbudowa_zebow_w_jeden_dzien.jpg'


const AllOn4 = (props) => {
	return (
		<Layout {...props}>
			<TitleHeader title="Odbudowa zębów w jeden dzień – All on 4" />
            <BlogPost post={{img: Odbudowa, text: article.text}}/>
		</Layout>
	);
};

const article = {
	text: `Czy ruszająca się proteza dolna przy jedzeniu lub dyskomfort, że wypadnie przy bardziej energicznym uśmiechu albo grymasie nie są Ci obce? A może pozostało Ci kilka zębów lub korzeni a wizja zabiegów koniecznych do wykonania by odbudować zęby Cię przeraża? Jak byś się czuł gdybyśmy zaproponowali Ci odbudowę wszystkich Twoich zębów w jeden dzień a w dodatku na stałe?
    Choć powyższe brzmi niewiarygodnie to odbudowa zębów w jeden dzień jest jak najbardziej możliwa dzięki metodzie all on 4. Dzięki temu rozwiązaniu już w jeden dzień możesz odzyskać estetykę uśmiechu, możliwość swobodnego spożywania pokarmów a także pewność, że zęby Ci nie „wypadną”. Na pierwszym spotkaniu konsultacyjnym będziemy chcieli poznać historię Twoich zębów a także wykonać radiologiczne badanie 3D aby ocenić warunki do wszczepienia implantów na których będą się opierać Twoje przyszłe zęby. Jeżeli ilość tkanki kostnej jest odpowiednia planujemy zabieg na którym zależnie od sytuacji usuniemy pozostałe nienadające się do odbudowy zęby i wszczepimy 4 implanty. Niejednokrotnie przed zabiegiem wszczepienia implantów konieczne będą wizyty na których wykonamy pogłębioną diagnostykę lub zaplanujemy przyszłą odbudowę. Zabieg przeprowadzamy w komfortowym znieczuleniu miejscowym. Na koniec montujemy Twoje nowe zęby na wszczepionych implantach. Od razu po zabiegu możesz cieszyć się nowym, piękny uśmiechem, jeść oraz mówić bez najmniejszych problemów. Ze względu na zmiany zachodzące w tkankach podczas gojenia konieczne jest wykonanie docelowej odbudowy protetycznej po około 4 miesiącach od zabiegu.
    Co najważniejsze większość pacjentów którzy zwlekali z decyzją o odbudowie zębów metodą all on 4 przekonuje się, że był to stracony czas a ich komfort i pewność siebie już dawno mogła być taka jak po zabiegu.
    `
}
export default AllOn4;
